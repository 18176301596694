import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authDataSlice';
import uiflowReducer from './slices/uiFlowSlice';
import attachmentsDataReducer from './slices/attachmentsDataSlice';
import uiFlowPermissionsSliceReducer from './slices/uiFlowPermissionsDataSlice';
import customerConfigurationSliceReducer from './slices/customerConfigurationSlice';
import consignmentSliceReducer from './slices/consignmentSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    uiflow: uiflowReducer,
    attachmentsData: attachmentsDataReducer,
    uiFlowPermissions: uiFlowPermissionsSliceReducer,
    customerConfiguration: customerConfigurationSliceReducer,
    consignment: consignmentSliceReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

// For testing purposes
const rootReducer = combineReducers({
  uiflow: uiflowReducer,
  attachmentsData: attachmentsDataReducer
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false
      })
  });
};

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;
