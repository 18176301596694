import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../.';
import { ModeValue } from 'src/types/uiflow/uiflow-types';
import { additionalDataReducers } from './reducers/additionalDataReducers';
import { ActionType } from 'src/views/uiflow/util/headerComponent/ActionsButtonGroup';
import { activeTabReducers } from './reducers/activeTabReducer';
import { flowNavigationReducer } from './reducers/flowNavigationReducer';
import { flowTitlesReducer } from './reducers/flowTitlesReducer';
import { addModeTabReducer } from './reducers/addModeTabReducer';
import { ButtonDefinition } from 'src/@vigo/vigo-multiButton';
import { itemViewToolbarReducer } from './reducers/itemViewToolbarReducer';
import { AdditionalDataPanel } from 'src/@core/hooks/useAdditionalDataPanel';

export interface UIFlowState {
  uiFlowMode: ModeValue;
  isItemView: boolean;
  currentlySelectedDataId: string;
  customHeaderComponentName: string;
  additionalId: string | undefined;
  disableMultiButton: boolean;
  disableTabPanelPadding: boolean;
  disableSingleComponentFlowBackgroundStyle: boolean;
  selectedItemId: string;
  requiresSplitView: boolean;
  isSingleComponentFlow: boolean;
  lockingOverlayActive: boolean;
  lockingOverlayMessage: string;
  activeTabState: {
    activeTabIndex: number;
    activeComponentName: string;
    activeComponentId: string;
    activeTabIsDirty: boolean;
    activeTabFunctions: Record<string, any>;
  };
  additionalDataState: {
    sidePanelClosed: boolean;
    additionalDataPanels: AdditionalDataPanel[];
    additionalDataTransferObject: Record<string, any>;
  };
  addModeTabsState: {
    addTabsStatuses: any[];
  };
  customToolbarState: {
    customToolbarActions: any[];
  };
  flowNavigationState: {
    flowNavigationStack: any[];
    flowDataIdArray: string[];
  };
  flowTitlesState: {
    headerComponentTitles: {
      main: string;
      item: string;
    };
    flowTitleForAddMode: string;
    flowTitleForEditMode: string;
  };
  formItemsState: {
    formContextResolver: any;
    disableDeliveryAddressDefaults: boolean;
    disableCollectionAddressDefaults: boolean;
  };
  itemViewToolbarState: {
    shouldPersistButtonSelection: boolean;
    saveButtonGroup: {
      addMode: ButtonDefinition[];
      editMode: ButtonDefinition[];
    };
  };
}

const initialState: UIFlowState = {
  uiFlowMode: 'initial',
  isItemView: false,
  currentlySelectedDataId: '',
  customHeaderComponentName: '',
  additionalId: undefined,
  disableMultiButton: false,
  disableTabPanelPadding: false,
  disableSingleComponentFlowBackgroundStyle: false,
  selectedItemId: '',
  requiresSplitView: false,
  isSingleComponentFlow: false,
  lockingOverlayActive: false,
  lockingOverlayMessage: '',
  activeTabState: {
    activeTabIndex: 0,
    activeComponentName: '',
    activeComponentId: '',
    activeTabIsDirty: false,
    activeTabFunctions: {}
  },
  additionalDataState: {
    sidePanelClosed: true,
    additionalDataPanels: [],
    additionalDataTransferObject: {}
  },
  addModeTabsState: {
    addTabsStatuses: []
  },
  customToolbarState: {
    customToolbarActions: []
  },
  flowNavigationState: {
    flowNavigationStack: [],
    flowDataIdArray: []
  },
  flowTitlesState: {
    headerComponentTitles: {
      main: '',
      item: ''
    },
    flowTitleForAddMode: '',
    flowTitleForEditMode: ''
  },
  formItemsState: {
    formContextResolver: undefined,
    disableDeliveryAddressDefaults: false,
    disableCollectionAddressDefaults: false
  },
  itemViewToolbarState: {
    shouldPersistButtonSelection: true,
    saveButtonGroup: {
      addMode: [],
      editMode: []
    }
  }
};

export const uiflowSlice = createSlice({
  name: 'uiflow',
  initialState: initialState,
  reducers: {
    setUIFlowMode: (state, action: PayloadAction<ModeValue>) => {
      state.uiFlowMode = action.payload;
    },
    setIsItemView: (state, action: PayloadAction<boolean>) => {
      state.isItemView = action.payload;
    },
    setForSingleComponentFlow: state => {
      state.uiFlowMode = 'singleComponent';
      state.isItemView = true;
      state.activeTabState.activeTabIndex = 0;
      state.currentlySelectedDataId = '';
      state.isSingleComponentFlow = true;
    },
    setDisableMultiButton: (state, action: PayloadAction<boolean>) => {
      state.disableMultiButton = action.payload;
    },

    setForAddNew: state => {
      state.uiFlowMode = 'add';
      state.isItemView = true;
      state.activeTabState.activeTabIndex = 0;
      state.currentlySelectedDataId = '';
    },
    setForLoadRecord: (
      state,
      action: PayloadAction<{ requiresSplitView: boolean; currentlySelectedDataId: string; mainComponentTitle: string }>
    ) => {
      state.uiFlowMode = 'edit';
      state.isItemView = true;
      state.requiresSplitView = action.payload.requiresSplitView;
      state.currentlySelectedDataId = action.payload.currentlySelectedDataId;
      state.flowTitlesState.headerComponentTitles.main = action.payload.mainComponentTitle;
    },
    setForMainComponentView: (
      state,
      action: PayloadAction<{
        headerComponentTitles: { main: string; item: string };
        requiresSplitView: boolean;
        isItemView: boolean;
        additionalDataTransferObject: Record<string, any>;
      }>
    ) => {
      state.flowTitlesState.headerComponentTitles.main = action.payload.headerComponentTitles.main;
      state.requiresSplitView = action.payload.requiresSplitView;
      state.isItemView = action.payload.isItemView;
      state.additionalDataState.additionalDataTransferObject = action.payload.additionalDataTransferObject;
    },
    setExitAddNew: state => {
      state.uiFlowMode = 'initial';
      state.isItemView = false;
      state.activeTabState.activeTabIsDirty = false;
    },
    setCurrentlySelectedDataId: (state, action: PayloadAction<string>) => {
      state.currentlySelectedDataId = action.payload;
    },
    setCustomHeaderComponentName: (state, action: PayloadAction<string>) => {
      state.customHeaderComponentName = action.payload;
    },
    setFormContextResolver: (state, action: PayloadAction<any>) => {
      state.formItemsState.formContextResolver = action.payload;
    },
    setDisableDeliveryAddressDefaults: (state, action: PayloadAction<boolean>) => {
      state.formItemsState.disableDeliveryAddressDefaults = action.payload;
    },
    setDisableCollectionAddressDefaults: (state, action: PayloadAction<boolean>) => {
      state.formItemsState.disableCollectionAddressDefaults = action.payload;
    },
    setLockingOverlay: (state, action: PayloadAction<boolean>) => {
      state.lockingOverlayActive = action.payload;
      if (action.payload === false) {
        state.lockingOverlayMessage = '';
      }
    },
    activateLockingOverlay: (state, action: PayloadAction<string>) => {
      state.lockingOverlayActive = true;
      state.lockingOverlayMessage = action.payload;
    },
    setCustomToolbarActions: (state, action: PayloadAction<ActionType[]>) => {
      state.customToolbarState.customToolbarActions = action.payload;
    },
    setSelectedItemId: (state, action: PayloadAction<string>) => {
      state.selectedItemId = action.payload;
    },
    setAdditionalId: (state, action: PayloadAction<string>) => {
      state.additionalId = action.payload;
    },
    setDisableTabPanelPadding: (state, action: PayloadAction<boolean>) => {
      state.disableTabPanelPadding = action.payload;
    },
    setDisableSingleComponentFlowBackgroundStyle: (state, action: PayloadAction<boolean>) => {
      state.disableSingleComponentFlowBackgroundStyle = action.payload;
    },
    ...additionalDataReducers,
    ...activeTabReducers,
    ...flowNavigationReducer,
    ...flowTitlesReducer,
    ...addModeTabReducer,
    ...itemViewToolbarReducer
  }
});

export const {
  setUIFlowMode,
  setIsItemView,
  setForSingleComponentFlow,
  setAddTabStatuses,
  setForAddNew,
  setActiveTabIndex,
  setForLoadRecord,
  setForMainComponentView,
  popNavigationStack,
  resetActiveTabsAndStatuses,
  setExitAddNew,
  incrementAddTabStatuses,
  setActiveTabDirtyProperty,
  setCurrentlySelectedDataId,
  setActiveComponentId,
  setActiveComponentName,
  setAdditionalDataTransferProp,
  resetAdditionalDataTransferObject,
  removeAdditionalDataProp,
  setCustomHeaderComponentName,
  setActiveTabFunctions,
  setFormContextResolver,
  setDisableDeliveryAddressDefaults,
  setDisableCollectionAddressDefaults,
  setLockingOverlay,
  activateLockingOverlay,
  setFlowDataIdArray,
  setCustomToolbarActions,
  setItemHeaderTitle,
  pushNavigationStack,
  setSelectedItemId,
  setFlowTitleForAddMode,
  setAdditionalId,
  setDisableTabPanelPadding,
  setPanels,
  addPanel,
  removePanel,
  setPanelClosed,
  onComponentChangeOrBreakpoint,
  resetToFixedPanel,
  setDisableSingleComponentFlowBackgroundStyle,
  setAddModeSaveButtonGroup,
  resetAddModeSaveButtonGroup,
  setEditModeSaveButtonGroup,
  resetEditModeSaveButtonGroup,
  resetAllSaveButtonGroups,
  setShouldPersistButtonSelection,
  setDisableMultiButton
} = uiflowSlice.actions;

export const selectUIFlowMode = (state: RootState) => state.uiflow.uiFlowMode;
export const selectActiveTabState = (state: RootState) => state.uiflow.activeTabState;
export const selectAdditionalDataPanelState = (state: RootState) => state.uiflow.additionalDataState;
export const selectAddModeTabsState = (state: RootState) => state.uiflow.addModeTabsState;
export const selectCustomToolbarState = (state: RootState) => state.uiflow.customToolbarState;
export const flowNavigationState = (state: RootState) => state.uiflow.flowNavigationState;
export const flowTitlesState = (state: RootState) => state.uiflow.flowTitlesState;
export const formItemsState = (state: RootState) => state.uiflow.flowTitlesState;

export default uiflowSlice.reducer;
