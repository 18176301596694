import { GetAxiosInstance } from '../baseService';
import { ConvertServiceLocationToEnum, ServiceLocation } from '../serviceLocationsAxios';
import {
  ExportModel,
  GetAddressTypeQueryParams,
  GetCustomerScanningOrderMetricsQueryParams,
  GetCustomerScanningOrderQueryParams,
  GetHazardousGoodsUnnumberByidQueryParams,
  GetHazardousGoodsUnnumberQueryParams,
  GetRevenueCodeQueryParams,
  GetSalesOrderItemQueryParams,
  GetSalesOrderRevenueQueryParams,
  GetSalesOrderStatusQueryParams,
  GetSalesOrderTrackingQueryParams,
  HazardousGoodsUnnumberDto,
  RevenueCodeDto,
  SalesOrderDtoApiResponse,
  SalesOrderItemDto,
  SalesOrderLineNoteTypeDto,
  SalesOrderListDto,
  SalesOrderStatusDto,
  SalesOrderTrackingDto,
  UnitCategoryDto,
  BillingUnitDtoApiResponse,
  BillingUnitModel,
  GetBillingUnitByidQueryParams,
  GetServiceProfileByidQueryParams,
  GetServiceProfileQueryParams,
  GetServiceProfileSurchargeQueryParams,
  GetShippingUnitQueryParams,
  GetSurchargeTypeByidQueryParams,
  ServiceProfileDto,
  ServiceProfileDtoApiResponse,
  ServiceProfileModel,
  ServiceProfileSurchargeDto,
  ServiceProfileSurchargeDtoApiResponseListApiResponse,
  ServiceProfileSurchargeModelBulkItemModel,
  ShippingUnitDto,
  ShippingUnitDtoApiResponse,
  ShippingUnitModel,
  SurchargeTypeDtoApiResponse,
  SurchargeTypeModel,
  GetShippingUnitByidQueryParams,
  UnitCodeProfileDto,
  GetUnitCodeProfileQueryParams,
  GetUnitCodeProfileByidQueryParams,
  UnitCodeProfileModel,
  UnitCodeProfileDtoApiResponse,
  UnitCodeProfileServiceProfileModelBulkItemModel,
  UnitCodeProfileServiceProfileDtoApiResponseListApiResponse,
  GetUnitCodeProfileServiceProfileQueryParams,
  UnitCodeProfileServiceProfileDto,
  UnitCodeDto,
  GetUnitCodeByidQueryParams,
  GetUnitCodeQueryParams,
  AddressTypeDto,
  UnitCodeModel,
  UnitCodeDtoApiResponse,
  ServiceTypeModel,
  ServiceTypeDtoApiResponse,
  GetSalesOrderConfigurationBycustomerIdQueryParams,
  SalesOrderConfigurationDto,
  UnitTypeDto,
  PropertyTypeDto,
  SalesOrderStatusHistoryDto,
  SalesOrderAuditDto,
  GetPodQueryParams,
  PodDto,
  ShippingUnitDimensionsModel,
  ShippingUnitDimensionsDtoApiResponse,
  GetSalesOrderByidQueryParams,
  GetSalesOrderQueryParams,
  SalesOrderDto,
  GetSalesOrderTypeQueryParams,
  SalesOrderTypeDto,
  GetUnitOfMeasureQueryParams,
  GetSalesOrderReferenceTypeQueryParams,
  SalesOrderReferenceDto,
  GetSalesOrderLineTypeQueryParams,
  SalesOrderLineTypeDto,
  GetServiceTypeQueryParams,
  ServiceTypeDto,
  GetOrderTypeQueryParams,
  OrderTypeDto,
  GetSurchargeTypeQueryParams,
  SurchargeTypeDto,
  GetBillingUnitQueryParams,
  BillingUnitDto,
  GetPropertyTypeQueryParams,
  GetSalesOrderStatusHistoryQueryParams,
  GetSalesOrderStatusGroupQueryParams,
  GetUnitTypeQueryParams,
  GetHazardousGoodsClassQueryParams,
  HazardousGoodsClassDto,
  GetUnitCategoryQueryParams,
  SalesOrderStatusGroupDto,
  SalesOrderRevenueDto,
  GetSalesOrderLineNoteTypeQueryParams,
  GetServiceTypeByidQueryParams,
  UnitOfMeasureDto,
  GetRegionalDistributionCentreTypeQueryParams,
  RegionalDistributionCentreTypeDto,
  GetCustomerWithStatusQueryParams,
  CustomerWithStatusViewDto,
  GetScheduleActivityTypeQueryParams,
  ScheduleActivityTypeDto
} from '../../models/salesorder';
import { AxiosRequestConfig } from 'axios';

/**
 * Get all sales orders
 *
 * @param params GetSalesOrderQueryParams
 * @returns SalesOrderViewDto
 */
export const getAllSalesOrders = (params?: GetSalesOrderQueryParams) => {
  return GetAxiosInstance().get<SalesOrderListDto[]>(`${ConvertServiceLocationToEnum('salesorder')}/SalesOrder`, {
    params
  });
};

/**
 * Request sales order export
 *
 * @param params GetSurchargeTypeQueryParams
 * @returns SurchargeTypeDto[]
 */
export const postSalesOrderExportRequest = (params?: ExportModel) => {
  return GetAxiosInstance().post<StringConstructor[]>(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrder/export`,
    {
      filter: params?.filter,
      sort: params?.sort,
      notifyOnCompletion: params?.notifyOnCompletion,
      showDeleted: params?.showDeleted,
      ignoreOwner: params?.ignoreOwner,
      columns: params?.columns
    }
  );
};

/**
 * Get all sales orders
 *
 * @param params GetSalesOrderQueryParams
 * @returns SalesOrderViewDto
 */
export const getAllSalesOrdersCount = (params?: GetSalesOrderQueryParams) => {
  return GetAxiosInstance().get<number>(`${ConvertServiceLocationToEnum('salesorder')}/SalesOrder/count`, {
    params
  });
};

/**
 * Get sales order types
 *
 * @param params GetSalesOrderTypeQueryParams
 * @returns SurchargeTypeDto[]
 */
export const getSalesOrderTypes = (params?: GetSalesOrderTypeQueryParams) => {
  return GetAxiosInstance().get<SalesOrderTypeDto[]>(`${ConvertServiceLocationToEnum('salesorder')}/SalesOrderType`, {
    params
  });
};

/**
 * Get sales order surcharge types
 *
 * @param params GetSurchargeTypeQueryParams
 * @returns SurchargeTypeDto[]
 */
export const getSalesOrderSurcharges = (params?: GetSurchargeTypeQueryParams) => {
  return GetAxiosInstance().get<SurchargeTypeDto[]>(`${ConvertServiceLocationToEnum('salesorder')}/SurchargeType`, {
    params
  });
};

/**
 * Get sales order billing units
 *
 * @param params GetBillingUnitQueryParams
 * @returns BillingUnitDto[]
 */
export const getSalesOrderBillingUnits = (params?: GetBillingUnitQueryParams) => {
  return GetAxiosInstance().get<BillingUnitDto[]>(`${ConvertServiceLocationToEnum('salesorder')}/BillingUnit`, {
    params
  });
};

/**
 * Get sales order service types
 *
 * @param params GetServiceTypeQueryParams
 * @returns ServiceTypeDto[]
 */
export const getSalesOrderServiceTypes = (params?: GetServiceTypeQueryParams) => {
  return GetAxiosInstance().get<ServiceTypeDto[]>(`${ConvertServiceLocationToEnum('salesorder')}/ServiceType`, {
    params
  });
};

/**
 * Get Service Type by Id
 * @param id Id of the Service Type
 * @param params GetServiceTypeByidQueryParams
 * @returns ServiceTypeDto
 */
export const getServiceTypeById = (id: string, params?: GetServiceTypeByidQueryParams) => {
  return GetAxiosInstance().get<ServiceTypeDto>(ServiceLocation.salesorder + '/ServiceType/' + id, { params });
};

/**
 * Get Order Types
 *
 * @param params GetOrderTypeQueryParams
 * @returns OrderTypeDto[]
 */
export const getOrderTypes = (params?: GetOrderTypeQueryParams) => {
  return GetAxiosInstance().get<OrderTypeDto[]>(`${ConvertServiceLocationToEnum('salesorder')}/OrderType`, {
    params
  });
};

/**
 * Get sales order reference types
 *
 * @param params GetSalesOrderReferenceTypeQueryParams
 * @returns SalesOrderReferenceDto[]
 */
export const getSalesOrderReferenceTypes = (params?: GetSalesOrderReferenceTypeQueryParams) => {
  return GetAxiosInstance().get<SalesOrderReferenceDto[]>(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrderReferenceType`,
    {
      params
    }
  );
};

/**
 * Get sales order line types
 *
 * @param params GetSalesOrderLineTypeQueryParams
 * @returns
 */
export const getSalesOrderLineTypes = (params?: GetSalesOrderLineTypeQueryParams) => {
  return GetAxiosInstance().get<SalesOrderLineTypeDto[]>(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrderLineType`,
    {
      params
    }
  );
};

/**
 * Get sales order status groups
 *
 * @param params GetSalesOrderStatusGroupQueryParams
 * @returns SalesOrderStatusGroupDto[]
 */
export const getSalesOrderStatusGroups = (params?: GetSalesOrderStatusGroupQueryParams) => {
  return GetAxiosInstance(undefined, undefined, undefined, undefined, undefined, undefined, true).get<
    SalesOrderStatusGroupDto[]
  >(`${ConvertServiceLocationToEnum('salesorder')}/SalesOrderStatusGroup`, {
    params
  });
};

/**
 * Get sales order unit of measures
 *
 * @param params GetUnitOfMeasureQueryParams
 * @returns UnitOfMeasureDto[]
 */
export const getSalesOrderUnitOfMeasures = (params?: GetUnitOfMeasureQueryParams) => {
  return GetAxiosInstance().get<UnitOfMeasureDto[]>(`${ConvertServiceLocationToEnum('salesorder')}/UnitOfMeasure`, {
    params
  });
};

/**
 * Get sales order by id
 *
 * @param id string
 * @param params GetSalesOrderByidQueryParams
 * @returns
 */
export const getSalesOrderById = (id: string, params?: GetSalesOrderByidQueryParams) => {
  return GetAxiosInstance().get<SalesOrderDto>(`${ConvertServiceLocationToEnum('salesorder')}/SalesOrder/${id}`, {
    params
  });
};

/**
 * Get sales order audit by id
 *
 * @param id string
 * @param params GetSalesOrderAuditByidQueryParams
 * @returns
 */
export const getSalesOrderAuditById = (id: string, partnerId: string, params?: GetSalesOrderByidQueryParams) => {
  return GetAxiosInstance(undefined, undefined, partnerId).get<SalesOrderAuditDto>(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrder/${id}/audit`,
    {
      params
    }
  );
};

/**
 * Get sales order unit codes
 *
 * @param params
 * @returns
 */
export const getSalesOrderUnitCodes = (params?: GetUnitCodeQueryParams) => {
  return GetAxiosInstance().get(`${ConvertServiceLocationToEnum('salesorder')}/UnitCode`, {
    params
  });
};

/**
 * Get sales order addresss types
 *
 * @param params GetAddressTypeQueryParams
 * @returns AddressTypeDto[]
 */
export const getSalesOrderAddressTypes = (params?: GetAddressTypeQueryParams) => {
  return GetAxiosInstance().get<AddressTypeDto[]>(`${ConvertServiceLocationToEnum('salesorder')}/AddressType`, {
    params
  });
};

/**
 * Save a new sales Order
 * @param params
 * @returns
 */
export const postSalesOrder = (params: { data: any }, partnerId?: string, source?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId, source).post(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrder`,
    params.data
  );
};

/**
 * Get sales order configuration by customer id.
 * @param customerId
 * @param params
 * @returns
 */
export const getSalesOrderSalesOrderConfiguration = (
  customerId: string,
  params?: GetSalesOrderConfigurationBycustomerIdQueryParams
) => {
  return GetAxiosInstance().get<SalesOrderConfigurationDto>(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrderConfiguration/${customerId}`,
    { params }
  );
};

/**
 * Save sales order line
 *
 * @param id string
 * @param params
 */
export const postSalesOrderLine = (params: { data: any }, partnerId?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId).post(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrderLine`,
    params.data
  );
};

/**
 * Get Sales Order Items
 * @param params GetSalesOrderItemQueryParams
 * @returns SalesOrderItemDto[]
 */
export const getSalesOrderItems = (params?: GetSalesOrderItemQueryParams, partnerId?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId).get<SalesOrderItemDto[]>(
    ServiceLocation.salesorder + '/SalesOrderItem',
    { params }
  );
};

/**
 * Save sales order item
 *
 * @param id string
 * @param params
 */
export const postSalesOrderItem = (params: { data: any }, partnerId?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId).post(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrderItem`,
    params.data
  );
};

/**
 * Save sales order reference
 *
 * @param id string
 * @param params
 */
export const postSalesOrderReference = (params: { data: any }, partnerId?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId).post(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrderReference`,
    params.data
  );
};

/**
 * Put sales order object
 *
 * @param id string
 * @param params
 */
export const putSalesOrder = (id: string, params: { data: any }, partnerId?: string, source?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId, source).put(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrder/${id}`,
    params.data
  );
};

/**
 * Put sales order lines
 * @param id string
 * @param params
 * @returns
 */
export const putSalesOrderLine = (id: string, params: { data: any }, partnerId?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId).put(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrderLine/${id}`,
    params.data
  );
};

/**
 * Put sales order items
 * @param id string
 * @param params
 * @returns
 */
export const putSalesOrderItem = (id: string, params: { data: any }, partnerId?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId).put(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrderItem/${id}`,
    params.data
  );
};

/**
 * Put sales order references
 * @param id string
 * @param params
 * @returns
 */
export const putSalesOrderReference = (id: string, params: { data: any }, partnerId?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId).put(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrderReference/${id}`,
    params.data
  );
};

/**
 * update sales order object from label print
 *
 * @param id string
 * @param params
 */
export const updateSalesOrder = (id: string, data: SalesOrderDto) => {
  return GetAxiosInstance(undefined, undefined, undefined).put(
    `${ConvertServiceLocationToEnum('salesorder')}/SalesOrder/${id}`,
    data
  );
};

/**
 * Get Service Code Profiles
 * @param params GetServiceProfileQueryParams
 * @returns ServiceProfileDto[]
 */
export const getServiceCodeProfiles = (params?: GetServiceProfileQueryParams) => {
  return GetAxiosInstance().get<ServiceProfileDto[]>(ServiceLocation.salesorder + '/ServiceProfile', { params });
};

/**
 * Get Service Code Profile by Id
 * @param id Id of the Service Code Profile
 * @param params GetServiceProfileByidQueryParams
 * @returns ServiceProfileDto
 */
export const getServiceCodeProfileById = (id: string, params?: GetServiceProfileByidQueryParams) => {
  return GetAxiosInstance().get<ServiceProfileDto>(ServiceLocation.salesorder + '/ServiceProfile/' + id, { params });
};

/**
 * Post Service Code Profile
 * @param model ServiceProfileModel
 * @returns ServiceProfileDtoApiResponse
 */
export const postServiceCodeProfile = (model: ServiceProfileModel) => {
  return GetAxiosInstance().post<ServiceProfileDtoApiResponse>(ServiceLocation.salesorder + '/ServiceProfile', model);
};

/**
 * Put Service Code Profile
 * @param id Id of the Service Code Profile
 * @param model ServiceProfileModel
 * @returns ServiceProfileDtoApiResponse
 */
export const putServiceCodeProfile = (id: string, model: ServiceProfileModel) => {
  return GetAxiosInstance().put<ServiceProfileDtoApiResponse>(
    ServiceLocation.salesorder + '/ServiceProfile/' + id,
    model
  );
};

/**
 * Get Service Profile Surcharges
 * @param params GetServiceProfileSurchargeQueryParams
 * @returns ServiceProfileSurchargeDto[]
 */
export const getServiceProfileSurcharges = (params?: GetServiceProfileSurchargeQueryParams) => {
  return GetAxiosInstance().get<ServiceProfileSurchargeDto[]>(ServiceLocation.salesorder + '/ServiceProfileSurcharge', {
    params
  });
};

/**
 * Bulk Put Service Profile Surcharges
 * @param models ServiceProfileSurchargeModelBulkItemModel[]
 * @returns ServiceProfileSurchargeDtoApiResponseListApiResponse
 */
export const bulkPutServiceProfileSurcharges = (models: ServiceProfileSurchargeModelBulkItemModel[]) => {
  return GetAxiosInstance().put<ServiceProfileSurchargeDtoApiResponseListApiResponse>(
    ServiceLocation.salesorder + '/ServiceProfileSurcharge',
    models
  );
};

/**
 * Bulk Delete Service Profile Surcharges
 * @param models ServiceProfileSurchargeModelBulkItemModel[]
 * @returns ServiceProfileSurchargeDtoApiResponseListApiResponse
 */
export const bulkDeleteServiceProfileSurcharges = (models: ServiceProfileSurchargeModelBulkItemModel[]) => {
  const reqBody: AxiosRequestConfig<ServiceProfileSurchargeModelBulkItemModel[]> = {
    data: models
  };

  return GetAxiosInstance().delete<ServiceProfileSurchargeDtoApiResponseListApiResponse>(
    ServiceLocation.salesorder + '/ServiceProfileSurcharge',
    reqBody
  );
};

/**
 * Get Surcharge Codes
 * @param params GetSurchargeTypeQueryParams
 * @returns SurchargeTypeDto[]
 */
export const getSurchargeCodes = (params?: GetSurchargeTypeQueryParams) => {
  return GetAxiosInstance().get<SurchargeTypeDto[]>(ServiceLocation.salesorder + '/SurchargeType', { params });
};

/**
 * Get Surcharge Code by Id
 * @param id Id of the Surcharge Code
 * @param params GetSurchargeTypeByidQueryParams
 * @returns SurchargeTypeDto
 */
export const getSurchargeCodeById = (id: string, params?: GetSurchargeTypeByidQueryParams) => {
  return GetAxiosInstance().get<SurchargeTypeDto>(ServiceLocation.salesorder + '/SurchargeType/' + id, { params });
};

/**
 * Post Surcharge Code
 * @param model SurchargeTypeModel
 * @returns SurchargeTypeDtoApiResponse
 */
export const postSurchargeCode = (model: SurchargeTypeModel) => {
  return GetAxiosInstance().post<SurchargeTypeDtoApiResponse>(ServiceLocation.salesorder + '/SurchargeType', model);
};

/**
 * Put Surcharge Code
 * @param id Id of the Surcharge Code
 * @param model SurchargeTypeModel
 * @returns SurchargeTypeDtoApiResponse
 */
export const putSurchargeCode = (id: string, model: SurchargeTypeModel) => {
  return GetAxiosInstance().put<SurchargeTypeDtoApiResponse>(
    ServiceLocation.salesorder + '/SurchargeType/' + id,
    model
  );
};

/**
 * Get Service Codes
 * @param params GetServiceTypeQueryParams
 * @returns ServiceTypeDto[]
 */
export const getServiceCodes = (params?: GetServiceTypeQueryParams) => {
  return GetAxiosInstance().get<ServiceTypeDto[]>(ServiceLocation.salesorder + '/ServiceType', { params });
};

/**
 * Post Service Code
 * @param model ServiceTypeModel
 * @returns ServiceTypeDtoApiResponse
 */
export const postServiceCode = (model: ServiceTypeModel) => {
  return GetAxiosInstance().post<ServiceTypeDtoApiResponse>(ServiceLocation.salesorder + '/ServiceType', model);
};

/**
 * Put Service Code
 * @param id Id of the Service Code
 * @param model ServiceTypeModel
 * @returns ServiceTypeDtoApiResponse
 */
export const putServiceCode = (id: string, model: ServiceTypeModel) => {
  return GetAxiosInstance().put<ServiceTypeDtoApiResponse>(ServiceLocation.salesorder + '/ServiceType/' + id, model);
};

/**
 * Get Unit Codes
 * @param params GetUnitCodeQueryParams
 * @returns UnitCodeDto[]
 */
export const getUnitCodes = (params?: GetUnitCodeQueryParams) => {
  return GetAxiosInstance().get<UnitCodeDto[]>(ServiceLocation.salesorder + '/UnitCode', { params });
};

/**
 * Get Unit Code by Id
 * @param id Id of the Unit Code
 * @param params GetUnitCodeByidQueryParams
 * @returns UnitCodeDto
 */
export const getUnitCodeById = (id: string, params?: GetUnitCodeByidQueryParams) => {
  return GetAxiosInstance().get<UnitCodeDto>(ServiceLocation.salesorder + '/UnitCode/' + id, { params });
};

/**
 * Post Unit Code
 * @param model UnitCodeModel
 * @returns UnitCodeDtoApiResponse
 */
export const postUnitCode = (model: UnitCodeModel) => {
  return GetAxiosInstance().post<UnitCodeDtoApiResponse>(ServiceLocation.salesorder + '/UnitCode', model);
};

/**
 * Put Unit Code
 * @param model UnitCodeModel
 * @returns UnitCodeDtoApiResponse
 */
export const putUnitCode = (id: string, model: UnitCodeModel) => {
  return GetAxiosInstance().put<UnitCodeDtoApiResponse>(ServiceLocation.salesorder + '/UnitCode/' + id, model);
};

/**
 * Get Billing Units
 * @param params GetBillingUnitQueryParams
 * @returns BillingUnitDto[]
 */
export const getBillingUnits = (params?: GetBillingUnitQueryParams) => {
  return GetAxiosInstance().get<BillingUnitDto[]>(ServiceLocation.salesorder + '/BillingUnit', { params });
};

/**
 * Get Billing Unit by Id
 * @param id Id of the Billing Unit
 * @param params GetBillingUnitByidQueryParams
 * @returns BillingUnitDto
 */
export const getBillingUnitById = (id: string, params?: GetBillingUnitByidQueryParams) => {
  return GetAxiosInstance().get<BillingUnitDto>(ServiceLocation.salesorder + '/BillingUnit/' + id, { params });
};

/**
 * Post Billing Unit
 * @param model BillingUnitModel
 * @returns BillingUnitDtoApiResponse
 */
export const postBillingUnit = (model: BillingUnitModel) => {
  return GetAxiosInstance().post<BillingUnitDtoApiResponse>(ServiceLocation.salesorder + '/BillingUnit', model);
};

/**
 * Put Billing Unit
 * @param id Id of the Billing Unit
 * @param model BillingUnitModel
 * @returns BillingUnitDtoApiResponse
 */
export const putBillingUnit = (id: string, model: BillingUnitModel) => {
  return GetAxiosInstance().put<BillingUnitDtoApiResponse>(ServiceLocation.salesorder + '/BillingUnit/' + id, model);
};

/**
 * Post Shipping Unit Dimensions
 * @param model ShippingUnitDimensionsModel
 * @returns ShippingUnitDimensionsDtoApiResponse
 */
export const postShippingUnitDimensions = async (model: ShippingUnitDimensionsModel) => {
  return GetAxiosInstance().post<ShippingUnitDimensionsDtoApiResponse>(
    ServiceLocation.salesorder + '/ShippingUnitDimensions',
    model
  );
};

/**
 * Put Shipping Unit Dimensions
 * @param id Id of the Shipping Unit Dimensions
 * @param model ShippingUnitDimensionsModel
 * @returns ShippingUnitDimensionsDtoApiResponse
 */
export const putShippingUnitDimensions = async (id: string, model: ShippingUnitDimensionsModel) => {
  return GetAxiosInstance().put<ShippingUnitDimensionsDtoApiResponse>(
    ServiceLocation.salesorder + '/ShippingUnitDimensions/' + id,
    model
  );
};

/**
 * Get Shipping Units
 * @param params GetShippingUnitQueryParams
 * @returns ShippingUnitDto[]
 */
export const getShippingUnits = (params?: GetShippingUnitQueryParams) => {
  return GetAxiosInstance().get<ShippingUnitDto[]>(ServiceLocation.salesorder + '/ShippingUnit', { params });
};

/**
 * Get Shipping Unit by Id
 * @param id Id of the Shipping Unit
 * @param params GetShippingUnitByidQueryParams
 * @returns ShippingUnitDto
 */
export const getShippingUnitById = (id: string, params?: GetShippingUnitByidQueryParams) => {
  return GetAxiosInstance().get<ShippingUnitDto>(ServiceLocation.salesorder + '/ShippingUnit/' + id, { params });
};

/**
 * Post Shipping Unit
 * @param model ShippingUnitModel
 * @returns ShippingUnitDtoApiResponse
 */
export const postShippingUnit = (model: ShippingUnitModel) => {
  return GetAxiosInstance().post<ShippingUnitDtoApiResponse>(ServiceLocation.salesorder + '/ShippingUnit', model);
};

/**
 * Put Shipping Unit
 * @param id Id of the Shipping Unit
 * @param model ShippingUnitModel
 * @returns ShippingUnitDtoApiResponse
 */
export const putShippingUnit = (id: string, model: ShippingUnitModel) => {
  return GetAxiosInstance().put<ShippingUnitDtoApiResponse>(ServiceLocation.salesorder + '/ShippingUnit/' + id, model);
};

/**
 * Get Unit Code Profile Service Profiles
 * @param params GetUnitCodeProfileQueryParams
 * @returns UnitCodeProfileDto[]
 */
export const getUnitCodeProfiles = (params?: GetUnitCodeProfileQueryParams) => {
  return GetAxiosInstance().get<UnitCodeProfileDto[]>(ServiceLocation.salesorder + '/UnitCodeProfile', { params });
};

/**
 * Get Unit Code Profile by Id
 * @param id Id of the Unit Code Profile
 * @param params GetUnitCodeProfileByidQueryParams
 * @returns UnitCodeProfileDto
 */
export const getUnitCodeProfileById = (id: string, params?: GetUnitCodeProfileByidQueryParams) => {
  return GetAxiosInstance().get<UnitCodeProfileDto>(ServiceLocation.salesorder + '/UnitCodeProfile/' + id, { params });
};

/**
 * Post Unit Code Profile
 * @param model UnitCodeProfileModel
 * @returns UnitCodeProfileDtoApiResponse
 */
export const postUnitCodeProfile = (model: UnitCodeProfileModel) => {
  return GetAxiosInstance().post<UnitCodeProfileDtoApiResponse>(ServiceLocation.salesorder + '/UnitCodeProfile', model);
};

/**
 * Put Unit Code Profile
 * @param id Id of the Unit Code Profile
 * @param model UnitCodeProfileModel
 * @returns UnitCodeProfileDtoApiResponse
 */
export const putUnitCodeProfile = (id: string, model: UnitCodeProfileModel) => {
  return GetAxiosInstance().put<UnitCodeProfileDtoApiResponse>(
    ServiceLocation.salesorder + '/UnitCodeProfile/' + id,
    model
  );
};

/**
 * Bulk Put Unit Code Profile Service Code Profiles
 * @param models UnitCodeProfileServiceProfileModelBulkItemModel[]
 * @returns UnitCodeProfileServiceProfileDtoApiResponseListApiResponse
 */
export const bulkPutUnitCodeProfileServiceCodeProfiles = (
  models: UnitCodeProfileServiceProfileModelBulkItemModel[]
) => {
  return GetAxiosInstance().put<UnitCodeProfileServiceProfileDtoApiResponseListApiResponse>(
    ServiceLocation.salesorder + '/UnitCodeProfileServiceProfile',
    models
  );
};

/**
 * Bulk Delete Unit Code Profile Service Code Profiles
 * @param models UnitCodeProfileServiceProfileModelBulkItemModel[]
 * @returns UnitCodeProfileServiceProfileDtoApiResponseListApiResponse
 */
export const bulkDeleteUnitCodeProfileServiceCodeProfiles = (
  models: UnitCodeProfileServiceProfileModelBulkItemModel[]
) => {
  const reqBody: AxiosRequestConfig<UnitCodeProfileServiceProfileModelBulkItemModel[]> = {
    data: models
  };

  return GetAxiosInstance().delete<UnitCodeProfileServiceProfileDtoApiResponseListApiResponse>(
    ServiceLocation.salesorder + '/UnitCodeProfileServiceProfile',
    reqBody
  );
};

/**
 * Get Unit Code Profile Service Profiles
 * @param params GetUnitCodeProfileServiceProfileQueryParams
 * @returns UnitCodeProfileServiceProfileDto[]
 */
export const getUnitCodeProfileServiceProfiles = (params?: GetUnitCodeProfileServiceProfileQueryParams) => {
  return GetAxiosInstance().get<UnitCodeProfileServiceProfileDto[]>(
    ServiceLocation.salesorder + '/UnitCodeProfileServiceProfile',
    { params }
  );
};

/**
 * Get sales order unit types
 *
 * @param params GetUnitTypeQueryParams
 * @returns UnitTypeDto[]
 */
export const getSalesOrderUnitTypes = (params?: GetUnitTypeQueryParams) => {
  return GetAxiosInstance().get<UnitTypeDto[]>(ServiceLocation.salesorder + '/UnitType', { params });
};

/**
 * Get hazardous goods classes
 *
 * @param params GetHazardousGoodsClassQueryParams
 * @returns HazardousGoodsClassDto[]
 */
export const getHazardousGoodsClasses = (params?: GetHazardousGoodsClassQueryParams) => {
  return GetAxiosInstance().get<HazardousGoodsClassDto[]>(ServiceLocation.salesorder + '/HazardousGoodsClass', {
    params
  });
};

/**
 * Get hazardous goods UN numbers
 * @param params GetHazardousGoodsUnnumberQueryParams
 * @returns
 */
export const getHazardousGoodsUnnumbers = (params?: GetHazardousGoodsUnnumberQueryParams) => {
  return GetAxiosInstance().get<HazardousGoodsUnnumberDto[]>(`${ServiceLocation.salesorder}/HazardousGoodsUnnumber`, {
    params
  });
};

/**
 * Get hazardous goods UN number by id
 * @param params GetHazardousGoodsUnnumberQueryParams
 * @returns
 */
export const getHazardousGoodsUnnumberById = (id: string, params?: GetHazardousGoodsUnnumberByidQueryParams) => {
  return GetAxiosInstance().get<HazardousGoodsUnnumberDto>(
    `${ServiceLocation.salesorder}/HazardousGoodsUnnumber/${id}`,
    {
      params
    }
  );
};

/**
 * Get unit categories
 *
 * @param params GetUnitCategoriesQueryParams
 * @returns UnitCategoryDto[]
 */
export const getUnitCategories = (params?: GetUnitCategoryQueryParams) => {
  return GetAxiosInstance().get<UnitCategoryDto[]>(ServiceLocation.salesorder + '/UnitCategory', { params });
};

/**
 * Get property types
 *
 * @param params GetPropertyTypeQueryParams
 * @returns PropertyTypeDto[]
 */
export const getPropertyTypes = (params?: GetPropertyTypeQueryParams) => {
  return GetAxiosInstance().get<PropertyTypeDto[]>(ServiceLocation.salesorder + '/PropertyType', { params });
};

/**
 * Get sales order status history
 *
 * @param params GetSalesOrderStatusHistoryQueryParams
 * @returns SalesOrderStatusHistoryDto
 */
export const getSalesOrderStatusHistory = (partnerId?: string, params?: GetSalesOrderStatusHistoryQueryParams) => {
  return GetAxiosInstance(undefined, undefined, partnerId).get<SalesOrderStatusHistoryDto[]>(
    ServiceLocation.salesorder + '/SalesOrderStatusHistory',
    {
      params
    }
  );
};

/**
 * Get sales order status groups
 *
 * @param params
 * @returns
 */
export const getSalesOrderStatusGroup = (params?: GetSalesOrderStatusGroupQueryParams) => {
  return GetAxiosInstance().get<SalesOrderStatusGroupDto[]>(ServiceLocation.salesorder + '/salesOrderStatusGroup', {
    params
  });
};

/**
 * Get sales order statuses
 *
 * @param params
 * @returns
 */
export const getSalesOrderStatuses = (params?: GetSalesOrderStatusQueryParams) => {
  return GetAxiosInstance().get<SalesOrderStatusDto[]>(ServiceLocation.salesorder + '/SalesOrderStatus', {
    params
  });
};

/**
 * Get sales order for pod
 *
 * @param params
 * @returns
 */
export const getPod = (params?: GetPodQueryParams) => {
  return GetAxiosInstance().get<PodDto>(ServiceLocation.salesorder + '/pod', {
    params
  });
};

/**
 * Get regional distribution center types
 *
 * @param params GetRegionalDistributionCentreTypeQueryParams
 * @returns RegionalDistributionCentreTypeDto[]
 */
export const getRegionalDistributionCentreTypes = (params?: GetRegionalDistributionCentreTypeQueryParams) => {
  return GetAxiosInstance().get<RegionalDistributionCentreTypeDto[]>(
    ServiceLocation.salesorder + '/RegionalDistributionCentreType',
    { params }
  );
};

/**
 * Delete a sales order by id
 *
 * @param params {id: string; data: AxiosRequestConfig<{ remarks: string; version: string }>;
}
 * @returns SalesOrderDtoApiResponse
 */
export const deleteSalesOrder = (params: {
  id: string;
  data: AxiosRequestConfig<{ remarks: string; version: string }>;
}) => {
  return GetAxiosInstance().delete<SalesOrderDtoApiResponse>(
    `${ServiceLocation.salesorder}/SalesOrder/${params.id}`,
    params.data
  );
};

/**
 * Restore a sales order by id
 *
 * @param params {
  id: string;
  data: AxiosRequestConfig<{ remarks: string; version: string }>;
}
 * @returns SalesOrderDtoApiResponse
 */
export const restoreSalesOrder = (params: {
  id: string;
  data: AxiosRequestConfig<{ remarks: string; version: string }>;
}) => {
  return GetAxiosInstance().put<SalesOrderDtoApiResponse>(
    `${ServiceLocation.salesorder}/SalesOrder/${params.id}/restore`,
    params.data
  );
};

/**
 * Get revenue codes
 *
 * @param params GetRevenueCodeQueryParams
 * @returns RevenueCodeDto[]
 */
export const getRevenueCodes = (params?: GetRevenueCodeQueryParams) => {
  return GetAxiosInstance().get<RevenueCodeDto[]>(`${ServiceLocation.salesorder}/RevenueCode`, { params });
};

/**
 * Get sales order revenue
 *
 * @param params
 * @returns
 */
export const getSalesOrderRevenue = (params?: GetSalesOrderRevenueQueryParams) => {
  return GetAxiosInstance().get<SalesOrderRevenueDto>(`${ServiceLocation.salesorder}/SalesOrderRevenue`, { params });
};

/**
 * Get Sales Order Tracking Information by Id
 * @param params GetSalesOrderTrackingQueryParams
 * @returns SalesOrderTrackingDto
 */
export const getSalesOrderTrackingInformation = (params: GetSalesOrderTrackingQueryParams) => {
  return GetAxiosInstance().get<SalesOrderTrackingDto[]>(ServiceLocation.salesorder + '/SalesOrder/tracking', {
    params
  });
};

/**
 * Get Sales Order Line Note Types
 *
 * @param params GetSalesOrderLineNoteTypeQueryParams
 * @returns SalesOrderLineNoteTypeDto[]
 */
export const getSalesOrderLineNoteTypes = (params?: GetSalesOrderLineNoteTypeQueryParams) => {
  return GetAxiosInstance().get<SalesOrderLineNoteTypeDto[]>(ServiceLocation.salesorder + '/SalesOrderLineNoteType', {
    params
  });
};

/**
 * Retrieves the customer scanning orders.
 *
 * @param {GetCustomerScanningOrderQueryParams} [params] - The query parameters for retrieving the customer scanning orders.
 * @return {AxiosPromise<SalesOrderListDto[]>} A promise that resolves with an array of SalesOrderListDto representing the customer scanning orders.
 */
export const getCustomerScanningOrders = (params?: GetCustomerScanningOrderQueryParams) => {
  return GetAxiosInstance().get<SalesOrderListDto[]>(ServiceLocation.salesorder + '/CustomerScanningOrder', { params });
};

/**
 * Retrieves the scanning metrics.
 *
 * @param {GetCustomerScanningOrderMetricsQueryParams} params - The query parameters for retrieving the metrics.
 * @return {AxiosPromise<Record<string, any>>} A promise that resolves with the scanning metrics.
 */
export const getCustomerScanningMetrics = (params: GetCustomerScanningOrderMetricsQueryParams) => {
  return GetAxiosInstance().get<Record<string, any>>(ServiceLocation.salesorder + '/CustomerScanningOrder/metrics', {
    params
  });
};

/**
 *
 * @param models Put Customer Scanning Order Bulk
 * @returns
 */
export const bulkPutCustomerScanningOrder = (models: any[]) => {
  return GetAxiosInstance().put<any>(ServiceLocation.salesorder + '/CustomerScanningOrder', models);
};

/**
 * Customer with status
 * @param params
 * @returns
 */
export const customersWithStatus = (params?: GetCustomerWithStatusQueryParams) => {
  return GetAxiosInstance().get<CustomerWithStatusViewDto[]>(ServiceLocation.salesorder + '/CustomerWithStatus', {
    params
  });
};

/**
 * Get Schedule Activity Types
 * @param params GetScheduleActivityTypeQueryParams
 * @returns ScheduleActivityTypeDto[]
 */
export const getScheduleActivityTypes = (params?: GetScheduleActivityTypeQueryParams) => {
  return GetAxiosInstance().get<ScheduleActivityTypeDto[]>(ServiceLocation.salesorder + '/ScheduleActivityType', {
    params
  });
};
