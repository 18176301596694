import { AllHTMLAttributes, ChangeEvent, FocusEvent, ForwardedRef, RefObject, useEffect, useState } from 'react';
import {
  FormHelperTextProps,
  IconButton,
  InputBaseProps,
  InputLabelProps,
  SxProps,
  TextField,
  Theme
} from '@mui/material';
import { useVigoTranslation } from 'src/@core/hooks/useVigoTranslation';
import React from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import ArticleIcon from '@mui/icons-material/Article';
import { useAdditionalDataPanel } from 'src/@core/hooks/useAdditionalDataPanel';

export interface ITextInput {
  type?: any;
  name: string;
  className?: string;
  id?: any;
  label?: string;
  value?: any;
  placeholder?: string;
  sx?: SxProps<Theme>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: any;
  InputProps?: InputBaseProps;
  inputProps?: AllHTMLAttributes<HTMLInputElement>;
  inputLabelProps?: Partial<InputLabelProps>;
  FormHelperTextProps?: FormHelperTextProps;
  disabled?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  error?: boolean;
  minLength?: number;
  maxLength?: number;
  errormessage?: string;
  maxRows?: number;
  minRows?: number;
  rows?: number;
  multiline?: boolean;
  inputRef?: RefObject<HTMLInputElement> | ForwardedRef<any>;
  autoFocus?: boolean;
  useDataGridContext?: boolean;
  dataGridContextParams?: GridRenderEditCellParams<any, any, any>;
  additionalDataOptions?: { id: string };
  required?: boolean;
  disableTranslation?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';

  /**
   * The minimum number value that the input can accept.
   */
  min?: number;

  /**
   * The maximum number value that the input can accept.
   */
  max?: number;
}

export const VigoTextInput = React.forwardRef(
  (
    {
      id,
      label,
      value,
      placeholder,
      className,
      onChange,
      onBlur,
      onFocus,
      onKeyDown = () => {
        return;
      },
      sx,
      InputProps,
      disabled,
      helperText,
      fullWidth,
      error,
      inputProps,
      maxRows,
      minRows,
      rows,
      multiline,
      type,
      name,
      inputRef,
      errormessage,
      autoFocus,
      useDataGridContext,
      dataGridContextParams,
      additionalDataOptions = undefined,
      inputLabelProps,
      required,
      variant = 'outlined',
      disableTranslation = false,
      minLength = 0,
      maxLength = 99999999999,
      FormHelperTextProps = {},
      min,
      max
    }: ITextInput,
    ref
  ) => {
    const [textValue, setTextValue] = useState<string | undefined>(value);

    let dataGridApiRef: any;
    if (useDataGridContext) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      dataGridApiRef = useGridApiContext();
    }

    const { t } = useVigoTranslation();

    const { togglePanel } = useAdditionalDataPanel();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (useDataGridContext && dataGridContextParams) {
        const field = dataGridContextParams.field;
        const id = dataGridContextParams.id;

        dataGridApiRef.current.setEditCellValue({ id, field, value: event.target.value });
      }

      if (min) {
        if (parseInt(event.target.value) < min) {
          event.target.value = min.toString();
        }
      }

      if (max) {
        if (parseInt(event.target.value) > max) {
          event.target.value = max.toString();
        }
      }

      onChange?.(event);
      setTextValue(event.target.value);
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      onFocus?.(event);
    };

    useEffect(() => {
      setTextValue(value);
    }, [value]);

    return (
      <TextField
        id={id}
        className={className}
        autoComplete="off"
        name={name}
        label={disableTranslation === false ? t(label) : label}
        value={textValue}
        fullWidth={fullWidth}
        placeholder={disableTranslation === false ? t(placeholder) : placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={handleFocus}
        onKeyDown={onKeyDown}
        sx={sx}
        variant={variant}
        size="small"
        disabled={disabled}
        required={required}
        helperText={error ? t(errormessage) : t(helperText)}
        InputProps={{
          ...(Boolean(additionalDataOptions) &&
            !disabled && {
              endAdornment: (
                <IconButton
                  onClick={() => togglePanel(additionalDataOptions?.id ?? '')}
                  sx={{ transform: 'translateX(10px)' }}>
                  <ArticleIcon />
                </IconButton>
              )
            }),
          ...InputProps
        }}
        inputProps={{
          minLength: minLength,
          maxLength: maxLength,
          className: 'vigo-hintstep-' + name + ' ',
          autoComplete: 'new-password',
          form: {
            autoComplete: 'off'
          },
          ...inputProps
        }}
        InputLabelProps={inputLabelProps}
        FormHelperTextProps={{ ...FormHelperTextProps }}
        error={error}
        maxRows={maxRows}
        minRows={minRows}
        rows={rows}
        multiline={multiline}
        type={type}
        inputRef={ref || inputRef}
        autoFocus={autoFocus}
      />
    );
  }
);
