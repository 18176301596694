import {
  MessageDto,
  GetMessageQueryParams,
  EmailMessageModel,
  MessageDtoApiResponse,
  GetMessagingServiceQueryParams,
  MessagingServiceDto,
  GetMessagingServiceTypeQueryParams,
  MessagingServiceTypeDto,
  GetMessageTypeQueryParams,
  MessageTypeDto,
  SmsRegulatoryComplianceInformationDto,
  SmsRegulatoryComplianceInformationModel,
  MessagingServiceModel
} from 'src/models/messaging';
import { GetAxiosInstance } from '../baseService';
import { ServiceLocation } from '../serviceLocationsAxios';

/**
 * Get Messages
 * @param params Messages
 * @returns MessageDto[]
 */
export const getMessages = async (params?: GetMessageQueryParams) => {
  return GetAxiosInstance().get<MessageDto[]>(ServiceLocation.messaging + '/Message', { params });
};

export const getMessage = async (id: string, params?: GetMessageQueryParams) => {
  return GetAxiosInstance().get<MessageDto[]>(ServiceLocation.messaging + '/Message/' + id, { params });
};

/**
 * Send Email Message
 * @param model EmailMessageModel
 * @returns MessageDtoApiResponse
 */
export const sendEmailMessage = async (model: EmailMessageModel) => {
  return GetAxiosInstance().post<MessageDtoApiResponse>(ServiceLocation.messaging + '/Message/email', model);
};

/**
 * Get Message Types
 * @param params GetMessageTypeQueryParams
 * @returns MessageTypeDto[]
 */
export const getMessageTypes = async (params?: GetMessageTypeQueryParams) => {
  return GetAxiosInstance().get<MessageTypeDto[]>(ServiceLocation.messaging + '/MessageType', { params });
};

/**
 * Get Messaging Services
 * @param params GetMessagingServiceQueryParams
 * @returns MessagingServiceDto[]
 */
export const getMessagingServices = async (params?: GetMessagingServiceQueryParams) => {
  return GetAxiosInstance().get<MessagingServiceDto[]>(ServiceLocation.messaging + '/MessagingService', { params });
};

/**
 * Post Messaging Services
 * @param params GetMessagingServiceQueryParams
 * @returns MessagingServiceDto[]
 */
export const postMessagingServices = async (model: MessagingServiceModel, params?: GetMessagingServiceQueryParams) => {
  return GetAxiosInstance().post<MessagingServiceDto[]>(ServiceLocation.messaging + '/MessagingServices', model, {
    params
  });
};

/**
 * Put Messaging Services
 * @param params GetMessagingServiceQueryParams
 * @returns MessagingServiceDto[]
 */
export const putMessagingServices = async (
  model: MessagingServiceModel,
  id: string,
  params?: GetMessagingServiceQueryParams
) => {
  return GetAxiosInstance().put<MessagingServiceDto[]>(ServiceLocation.messaging + '/MessagingServices/' + id, model, {
    params
  });
};

/**
 * Get Messaging Service Types
 * @param params GetMessagingServiceTypeQueryParams
 * @returns MessagingServiceTypeDto[]
 */
export const getMessagingServiceTypes = async (params?: GetMessagingServiceTypeQueryParams) => {
  return GetAxiosInstance().get<MessagingServiceTypeDto[]>(ServiceLocation.messaging + '/MessagingServiceType', {
    params
  });
};

/**
 * Get Messaging Service By Id
 * @param id string
 * @param params GetMessagingServiceQueryParams
 * @returns MessagingServiceDto
 */
export const getMessagingServicesComplianceById = async (id: string) => {
  return GetAxiosInstance().get<SmsRegulatoryComplianceInformationDto>(
    ServiceLocation.messaging + '/MessagingServices/' + id + '/compliance'
  );
};

/**
 * Patch Messaging Service Compliance By Id
 * @param id string
 * @param model SmsRegulatoryComplianceInformationModel
 * @returns SmsRegulatoryComplianceInformationDto
 */
export const patchMessagingServicesComplianceById = async (
  id: string,
  model: SmsRegulatoryComplianceInformationModel
) => {
  return GetAxiosInstance().patch<SmsRegulatoryComplianceInformationDto>(
    ServiceLocation.messaging + '/MessagingServices/' + id + '/compliance',
    model
  );
};
