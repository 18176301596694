import { current, type PayloadAction } from '@reduxjs/toolkit';
import { UIFlowState } from '..';
import { AdditionalDataPanel } from 'src/@core/hooks/useAdditionalDataPanel';

const setPanels = (state: UIFlowState, action: PayloadAction<AdditionalDataPanel[]>) => {
  state.additionalDataState.additionalDataPanels = action.payload;

  if (action.payload.length === 0) {
    state.additionalDataState.sidePanelClosed = true;
  }
};

const resetToFixedPanel = (state: UIFlowState) => {
  const panels = state.additionalDataState.additionalDataPanels;
  const fixed = panels.filter(panel => panel?.fixed);
  if (fixed.length > 0) {
    fixed[0]['open'] = true;
  }

  state.additionalDataState.additionalDataPanels = fixed;
  state.additionalDataState.sidePanelClosed = fixed.length > 0 ? false : true;
};

const addPanel = (state: UIFlowState, action: PayloadAction<AdditionalDataPanel>) => {
  const existingPanel = current(state).additionalDataState.additionalDataPanels.find(
    existingPanel => existingPanel.id === action.payload.id
  );

  if (!existingPanel) {
    state.additionalDataState.additionalDataPanels = [
      ...state.additionalDataState.additionalDataPanels,
      { ...action.payload }
    ];

    // state.additionalDataState.additionalDataPanels.push(action.payload);

    if (action.payload?.fixed && action.payload?.open) {
      state.additionalDataState.sidePanelClosed = false;
    }
  }
};

const removePanel = (state: UIFlowState, action: PayloadAction<string>) => {
  const id = action.payload;
  const index = state.additionalDataState.additionalDataPanels.findIndex(panel => panel.id === id);

  if (index >= 0) {
    state.additionalDataState.additionalDataPanels.splice(index, 1);
  }
};

const setPanelClosed = (state: UIFlowState, action: PayloadAction<boolean>) => {
  state.additionalDataState.sidePanelClosed = action.payload;
};

const onComponentChangeOrBreakpoint = (state: UIFlowState, action: PayloadAction<AdditionalDataPanel>) => {
  const existingPanel = current(state).additionalDataState.additionalDataPanels.find(
    existingPanel => existingPanel.id === action.payload.id
  );
  if (!existingPanel) {
    state.additionalDataState.additionalDataPanels.push(action.payload);
    state.additionalDataState.sidePanelClosed = false;
  }
};

const setAdditionalDataTransferProp = (state: UIFlowState, action: PayloadAction<Record<string, any>>) => {
  const [[property, value]] = Object.entries(action.payload);
  state.additionalDataState.additionalDataTransferObject[property] = value;
};

const resetAdditionalDataTransferObject = (state: UIFlowState) => {
  state.additionalDataState.additionalDataTransferObject = {};
};

const removeAdditionalDataProp = (state: UIFlowState, action: PayloadAction<string>) => {
  if (Object.hasOwn(state.additionalDataState.additionalDataTransferObject, action.payload)) {
    delete state.additionalDataState.additionalDataTransferObject[action.payload];
  }
};

export const additionalDataReducers = {
  setPanels,
  addPanel,
  removePanel,
  setPanelClosed,
  onComponentChangeOrBreakpoint,
  setAdditionalDataTransferProp,
  resetAdditionalDataTransferObject,
  removeAdditionalDataProp,
  resetToFixedPanel
};
