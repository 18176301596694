import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const useVigoTranslation = () => {
  const { t } = useTranslation();

  const tenantConfig = sessionStorage.getItem('account-configurations');

  let origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

  const showFailedTransForTest = origin.toString().toLowerCase().includes('vigocloud.net') ? true : false;

  if (!origin.toString().toLowerCase().includes('localhost')) {
    origin = origin.toString().replace('http://', 'https://');
  }

  const translate = useCallback(
    (text?: string) => {
      let tt: string = t(text != null ? text : '');
      if (tt == '') {
        tt = text != null ? text : '';
      }

      if (
        !process.env.NODE_ENV ||
        ((showFailedTransForTest || process.env.NODE_ENV === 'development') &&
          !i18next.exists(text != undefined ? text : ''))
      ) {
        if (text != '') {
          tt = '_' + tt;
        } else {
          tt = '';
        }
      }

      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        if (text != '') {
          tt = tt;
        } else {
          tt = '';
        }
      }

      if (tenantConfig !== null) {
        const obj = JSON.parse(tenantConfig);

        const tenantTranslation = obj.translations.find((x: { label: string | undefined }) => x.label == text);
        if (tenantTranslation) {
          tt = tenantTranslation.tanslatedText;
        }
      }

      if (text === undefined) {
        tt = '';
      }

      return tt;
    },
    [showFailedTransForTest, t, tenantConfig]
  );

  return useMemo(() => ({ t: translate }), [translate]);
};
