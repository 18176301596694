import { useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

/**
 * EXAMPLE USE:
 *
 * const {set} = useConfirmDialog();
 *
 * const handleConfirmDialog = async () => {
    const result = await set(
      'Do you wish to save?',
      () => //console.log('confirm confirmed'),
      () => //console.log('confirm denied')
    );

    //console.log({ result });
  };
 *
 */

export const useConfirmDialog = (showDenyButton = true, confirmButtonText = 'Yes', denyButtonText = 'No') => {
  const theme = useTheme();

  const set = useCallback(
    (title: string, confirmedCallback?: () => any, deniedCallback?: () => any, footer?: string) => {
      const options: SweetAlertOptions = {
        text: Boolean(title) ? title : 'Confirm message here!',
        footer: footer,
        showDenyButton,
        confirmButtonText,
        denyButtonText,
        reverseButtons: true,
        customClass: {
          confirmButton: 'swal-confirm-button',
          denyButton: 'swal-deny-button'
        }
      };

      if (theme.palette.mode === 'dark') {
        options.background = '#1c1c1c';
        options.color = '#f2f2f2';
      }

      return Swal.fire(options).then(result => {
        if (result.isConfirmed) {
          if (typeof confirmedCallback === 'function') confirmedCallback();

          return true;
        } else if (result.isDenied) {
          if (typeof deniedCallback === 'function') deniedCallback();

          return false;
        } else {
          return false;
        }
      });
    },
    [showDenyButton, confirmButtonText, denyButtonText, theme.palette.mode]
  );

  return useMemo(() => ({ set }), [set]);
};
