import { useEffect, useState } from 'react';
import { VigoAttachment } from '..';
import {
  Box,
  Card,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Stack,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import useFileUploader from '../helpers/useFileUploader';
import { VigoTypography } from '../../vigo-typography';
import { VigoSelectBox } from '../../vigo-select';
import styles from '../vigofiledrop.module.css';
import { DeleteOutline } from 'mdi-material-ui';
import Search from '@mui/icons-material/Search';

export interface FileAttachmentsProps {
  file: VigoAttachment;
  index: number;
  attachmentTypes: any[];
  entityId: string;
  entityTypeId: string;
  comments?: string;
  presignedUploadUrl?: string;
  openFileViewer: () => void;
  handleDeleteFile: () => void;
  handleUploadSucess: (file: VigoAttachment, index: number) => void;
  disableUpload?: boolean;
  readOnly?: boolean;
}

const FileCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: 'none',
  borderColor: '#eee',
  borderStyle: 'solid',
  borderRadius: '5px',
  borderWidth: theme.palette.mode === 'light' ? '0px 0px 1px 0px' : '1px',
  backgroundColor: theme.palette.mode === 'light' ? '#f5f5f7' : 'none',
  padding: '12px'
}));

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  const { value } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export const FileAttachment = ({
  file,
  // index,
  attachmentTypes,
  entityId,
  entityTypeId,
  openFileViewer,
  handleDeleteFile,
  handleUploadSucess,
  disableUpload = false,
  readOnly = false
}: FileAttachmentsProps) => {
  const [attachmentError, setAttachmentError] = useState<string | undefined | null>(null);
  const { uploadAttachment, progress, error } = useFileUploader();

  // Remove being able to upload pod's and status images for now
  const attachmentTypesAvailable = attachmentTypes.filter(type => !type.isPod && !type.isStatusImage);

  function UploadAttachment(file: any) {
    if (disableUpload) return;

    if (file.attachmentTypeId === undefined || file.attachmentTypeId === '' || file.attachmentTypeId === null) {
      setAttachmentError('Please select paperwork type');

      return;
    } else {
      setAttachmentError(null);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('entityId', entityId);
      formData.append('attachmentTypeId', file.attachmentTypeId);
      formData.append('entityTypeId', entityTypeId);
      formData.append('comments', '');
      formData.append('metaData', '{}');

      uploadAttachment.mutate(formData, {
        onSuccess: (res: any) => {
          file.attachmentDtoId = res.data.data.id;
          file.uploaded = true;
        }
      });

      if (handleUploadSucess) {
        handleUploadSucess;
      }
    }
  }

  useEffect(() => {
    if (!file.id && !file.uploaded) {
      UploadAttachment(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onFileAttachmentTypeChange(event: any, file: VigoAttachment) {
    file.attachmentTypeId = event.target.value;
    UploadAttachment(file);
  }

  return (
    <>
      <FileCard key={file.name}>
        <Stack direction={'column'}>
          <VigoTypography value={file.name} disableTranslation className={styles.listFileName} />
          {!disableUpload && (
            <>
              <Box>
                <VigoSelectBox
                  autoWidth
                  variant={'standard'}
                  value={file.attachmentTypeId}
                  datasource={file.attachmentTypeId === null || undefined ? attachmentTypesAvailable : attachmentTypes}
                  onChange={(e: any) => onFileAttachmentTypeChange(e, file)}
                  name={'paperWorkTypeSelectBox'}
                  disabled={file.attachmentTypeId === null || undefined ? false : true}></VigoSelectBox>
              </Box>
              <Box>
                <Stack>
                  {uploadAttachment.isLoading && <LinearProgressWithLabel value={progress} />}
                  {uploadAttachment.isError && (
                    <VigoTypography variant="caption" value={error} disableTranslation></VigoTypography>
                  )}
                  {attachmentError !== '' && attachmentError !== undefined && attachmentError !== null && (
                    <VigoTypography variant="caption" value={attachmentError} disableTranslation></VigoTypography>
                  )}
                </Stack>
              </Box>
            </>
          )}
        </Stack>

        <Stack direction={'row'} gap="12px">
          <Tooltip title="View" componentsProps={{ tooltip: { sx: { color: '#fff' } } }}>
            <IconButton
              sx={{
                color: theme => theme.palette.vigoColors.gray,
                width: '18px',
                height: '18px',
                '&:hover': { color: theme => theme.palette.customColors.primaryGradient }
              }}
              onClick={openFileViewer}>
              <Search sx={{ width: '18px', height: '18px' }} />
            </IconButton>
          </Tooltip>
          {!readOnly && (
            <Tooltip title="Delete" componentsProps={{ tooltip: { sx: { color: '#fff' } } }}>
              <IconButton
                sx={{
                  color: theme => theme.palette.vigoColors.gray,
                  width: '18px',
                  height: '18px',
                  '&:hover': { color: theme => theme.palette.customColors.primaryGradient }
                }}
                onClick={() => handleDeleteFile()}>
                <DeleteOutline sx={{ width: '18px', height: '18px' }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </FileCard>
    </>
  );
};
