import { SyntheticEvent, useEffect, useState } from 'react';
import { Settings } from 'src/@core/context/settingsContext';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiMenu, { MenuProps } from '@mui/material/Menu';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useSettings } from 'src/@core/hooks/useSettings';
import LanguageDropdown from './LanguageDropdown';
import { Products, useGetProductLevel } from '../../../utils/getProductLevel';
import { VigoTypography } from 'src/@vigo/vigo-typography';
import DarkModeSwitch from 'src/layouts/components/panels/userprofile/components/DarkModeSwitch';
import { ApplicationCogOutline } from 'mdi-material-ui';
import { VigoButton } from 'src/@vigo/vigo-button';
import { VigoTooltip } from 'src/@vigo/vigo-tooltip';
import { useConfirmDialog } from 'src/@core/hooks/useConfirmDialog';
import { useRouter } from 'next/router';

const Menu = styled(MuiMenu)<MenuProps>(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}));

const MenuItemTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  flex: '1 1 100%',
  overflow: 'hidden',
  fontSize: '1rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginBottom: theme.spacing(0.75)
}));

const MenuItemSubtitle = styled(Typography)<TypographyProps>({
  flex: '1 1 100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
});

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 4)
}));

interface Props {
  hideLabel?: boolean;
}

const AppSettings = (props: Props) => {
  const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

  const router = useRouter();

  const { hideLabel } = props;

  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);

  const { settings, saveSettings } = useSettings();
  const { appShowDeleted, showUATRecords, direction, showPageHints } = settings;
  const prod = useGetProductLevel();

  const { set } = useConfirmDialog();

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (field: keyof Settings, value: Settings[keyof Settings]): void => {
    saveSettings({ ...settings, [field]: value });
  };

  useEffect(() => {
    if (prod === Products.MyPortal) {
      saveSettings({ ...settings, developerMode: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prod]);

  const handleClear = () => {
    set('Are you sure you wish to clear all your user settings and layouts?', () => {
      localStorage.clear();
    });
  };

  const handleCallGridDesigner = () => {
    router.push('/dashboards/development/gridLayoutBuilder');
  };

  return (
    <>
      <IconButton sx={{ marginTop: '20px' }} color="inherit" aria-haspopup="true" onClick={handleDropdownOpen}>
        <ApplicationCogOutline />
        {!hideLabel && <VigoTypography sx={{ marginLeft: '10px' }} value="Settings" />}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}>
        <Container
          sx={{
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}>
          <MenuItemTitle>Application Settings</MenuItemTitle>
          <MenuItemSubtitle variant="body2">Customise your experience</MenuItemSubtitle>
        </Container>

        <Container>
          {/* Language Selection */}
          <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography>Change Language</Typography>
            <LanguageDropdown settings={settings} saveSettings={saveSettings} />
          </Box>

          <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <VigoTypography value="Show UAT Consignments" />
            <DarkModeSwitch
              name="showUATRecords"
              checked={showUATRecords}
              onChange={e => handleChange('showUATRecords', e.target.checked)}
            />
          </Box>

          {/* Show Deleted */}
          {prod != Products.MyPortal && (
            <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>Show Deleted Records</Typography>
              <DarkModeSwitch
                name="appShowDeleted"
                checked={appShowDeleted}
                onChange={e => handleChange('appShowDeleted', e.target.checked)}
              />
            </Box>
          )}

          {/* Show Page Hints */}
          <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography>Show Page Hints</Typography>
            <DarkModeSwitch
              name="showPageHints"
              checked={showPageHints}
              onChange={e => handleChange('showPageHints', e.target.checked)}
            />
          </Box>

          {origin.toString().toLowerCase().includes('localhost') && (
            <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <VigoButton
                variant="outlined"
                fullWidth
                onClick={handleCallGridDesigner}
                name="clearCache"
                value="Grid Layout Designer"></VigoButton>
            </Box>
          )}

          <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <VigoTooltip disableTranslation title={'Reset all layout and settings to default'}>
              <VigoButton
                variant="contained"
                fullWidth
                onClick={handleClear}
                name="clearCache"
                value="Clear Application Cache"></VigoButton>
            </VigoTooltip>
          </Box>
        </Container>
      </Menu>
    </>
  );
};

export default AppSettings;
