import { useCallback, useEffect, useMemo, useRef } from 'react';
// import { useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store/baseHooks';
import {
  addPanel,
  onComponentChangeOrBreakpoint,
  removePanel,
  setPanelClosed,
  setPanels
} from 'src/store/slices/uiFlowSlice';
import { store } from 'src/store';

export interface AdditionalDataPanel {
  id: string;
  open?: boolean;
  fixed?: boolean;
  componentName: string;
  name?: string;
  title?: string;
  closeCallback?: () => void;
}

interface UseAdditionalDataPanelReturn {
  setAdditionalDataPanels: (panels: AdditionalDataPanel[]) => void;
  addAdditionalDataPanel: (panel: AdditionalDataPanel) => void;
  removeAdditionalDataPanel: (id: string) => void;
  resetAdditionalDataPanels: () => void;
  closeAnyOpenPanels: () => void;
  togglePanel: (id: string) => void;
  sidePanelClosed: boolean;
  setSidePanelClosed: (val: boolean) => void;
  panelIsOpen: (id: string) => boolean;
  panelComponentChangeOrBreakpointHit: (panel: AdditionalDataPanel) => void;
}

/**
 * For managing the registration and state of flow, tab, and field level additional data panels.
 *
 */
export const useAdditionalDataPanel = (): UseAdditionalDataPanelReturn => {
  const timeout = useRef<NodeJS.Timeout>();
  const PANEL_TOGGLE_TIMEOUT = 300;

  // const isOver900px = useMediaQuery('(min-width: 900px)');

  const dispatch = useAppDispatch();
  const additionalDataPanels = useAppSelector(state => state.uiflow.additionalDataState.additionalDataPanels);
  const sidePanelClosed = useAppSelector(state => state.uiflow.additionalDataState.sidePanelClosed);

  /**
   * Set entire additional panel array
   *
   * @param panels
   */
  const setAdditionalDataPanels = useCallback(
    (panels: AdditionalDataPanel[]) => {
      const payload = panels.map(panel => ({ open: false, fixed: false, name: '', title: '', ...panel }));

      dispatch(setPanels(payload));
    },
    [dispatch]
  );

  /**
   * Add a single panel to panel array
   *
   */
  const addAdditionalDataPanel = useCallback(
    (panel: AdditionalDataPanel) => {
      dispatch(addPanel(panel));
    },
    [dispatch]
  );

  /**
   * Remove a single panel from panel array
   *
   */
  const removeAdditionalDataPanel = useCallback(
    (id: string) => {
      dispatch(removePanel(id));
    },
    [dispatch]
  );

  /**
   * Reset panel array to []
   *
   */
  const resetAdditionalDataPanels = useCallback(() => {
    dispatch(setPanels([]));
  }, [dispatch]);

  /**
   * Set the sidePanelClosedProperty
   *
   * @param val
   */
  const setSidePanelClosed = useCallback(
    (val: boolean) => {
      dispatch(setPanelClosed(val));
    },
    [dispatch]
  );

  /**
   * Close any open side panels
   *
   */
  const closeAnyOpenPanels = useCallback(() => {
    let panelsToCheck = additionalDataPanels;
    if (store.getState().uiflow.additionalDataState.additionalDataPanels !== additionalDataPanels) {
      panelsToCheck = store.getState().uiflow.additionalDataState.additionalDataPanels;
    }
    const payload = panelsToCheck.map(panel => ({ ...panel, open: false }));
    dispatch(setPanels(payload));
  }, [additionalDataPanels, dispatch]);

  /**
   * Toggle a specific panels open state
   *
   * @param id
   */
  const togglePanel = useCallback(
    (id: string) => {
      let panelsToCheck = additionalDataPanels;
      if (store.getState().uiflow.additionalDataState.additionalDataPanels !== additionalDataPanels) {
        panelsToCheck = store.getState().uiflow.additionalDataState.additionalDataPanels;
      }

      closeAnyOpenPanels();

      timeout.current = setTimeout(() => {
        const foundIndex = panelsToCheck.findIndex(panel => panel.id === id);

        if (foundIndex < 0) return;

        const newPanels: AdditionalDataPanel[] = panelsToCheck.map((panel: AdditionalDataPanel, index: number) => {
          if (foundIndex === index) {
            return { ...panel, open: !panel.open };
          }

          return { ...panel, open: false };
        });

        if (newPanels.every(panel => !panel.open)) {
          const fixedPanelIndex = panelsToCheck.findIndex(panel => panel.fixed);

          // if (isOver900px && fixedPanelIndex > -1) {
          if (fixedPanelIndex > -1) {
            newPanels[fixedPanelIndex].open = true;
          }
        }

        setAdditionalDataPanels(newPanels);

        if (newPanels.every(panel => !panel.open)) {
          setSidePanelClosed(true);
        } else {
          setSidePanelClosed(false);
        }
      }, PANEL_TOGGLE_TIMEOUT);
    },
    [additionalDataPanels, closeAnyOpenPanels, setAdditionalDataPanels, setSidePanelClosed]
  );

  /**
   * Is panel with provided id in open state
   *
   * @param id string
   * @returns boolean
   */
  const panelIsOpen = useCallback(
    (id: string): boolean => {
      let panelsToCheck = additionalDataPanels;
      if (store.getState().uiflow.additionalDataState.additionalDataPanels !== additionalDataPanels) {
        panelsToCheck = store.getState().uiflow.additionalDataState.additionalDataPanels;
      }

      const panel = panelsToCheck.find(panel => panel.id === id);

      if (!panel) return false;

      return panel.open ?? false;
    },
    [additionalDataPanels]
  );

  /**
   * Control presence of additional tab panel if window width breakpoint is hit
   * or additional data panel component changes.
   *
   * @param panel AdditionalDataPanel
   */
  const panelComponentChangeOrBreakpointHit = useCallback(
    (panel: AdditionalDataPanel) => {
      dispatch(onComponentChangeOrBreakpoint(panel));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  return useMemo(
    () => ({
      setAdditionalDataPanels,
      addAdditionalDataPanel,
      removeAdditionalDataPanel,
      resetAdditionalDataPanels,
      closeAnyOpenPanels,
      togglePanel,
      sidePanelClosed,
      setSidePanelClosed,
      panelIsOpen,
      panelComponentChangeOrBreakpointHit
    }),
    [
      addAdditionalDataPanel,
      closeAnyOpenPanels,
      panelComponentChangeOrBreakpointHit,
      panelIsOpen,
      removeAdditionalDataPanel,
      resetAdditionalDataPanels,
      setAdditionalDataPanels,
      setSidePanelClosed,
      sidePanelClosed,
      togglePanel
    ]
  );
};
