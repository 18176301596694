// ** React Imports
import { ReactNode, ReactElement, useRef, useEffect, useState } from 'react';

// ** Hooks Import
import { useAuth } from 'react-oidc-context';

//import for runtime config
import getConfig from 'next/config';
import { RecentHistory } from 'src/layouts/components/panels/recenthistory/RecentHistoryPanel';
import { useSettings } from 'src/@core/hooks/useSettings';

import { ProductFruits } from 'react-product-fruits';
import { getUserByIdAsync } from 'src/services/identity';
import { User } from 'oidc-client-ts';
import { useAppDispatch } from 'src/store/baseHooks';
import { setAuthData } from 'src/store/slices/authDataSlice';
import { useVigoLogout } from 'src/@core/hooks/useVigoLogout';
import router from 'next/router';
import { Products, useGetProductLevel } from 'src/@core/utils/getProductLevel';

interface AuthGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
  hideSpinner: boolean;
}

declare type ProductFruitsUserObject = {
  username: string;
  email?: string;
  role?: string;
  signUpAt?: string;
  firstname?: string;
  lastname?: string;
  props?: UserCustomProps;
  group?: {
    groupId?: string;
    props?: UserGroupCustomProps;
  };
};
declare type UserCustomProps = {
  [key: string]: string | number | Array<string> | Array<number> | UserCustomProps;
};
declare type UserGroupCustomProps = {
  [key: string]: string | number | Array<string> | Array<number> | UserCustomProps;
};

const { publicRuntimeConfig } = getConfig();

const AuthGuard = (props: AuthGuardProps) => {
  const [oidcStorage, setOidcStorage] = useState<string | null>(null);

  const { children, fallback, hideSpinner } = props;
  const auth = useAuth();
  const { handleLogout } = useVigoLogout();

  const defaultUserInfo: ProductFruitsUserObject = {
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    signUpAt: '',
    role: '',
    props: {}
  };

  const { settings, saveSettings } = useSettings();
  const [pfUserInfo, setPfUserInfo] = useState<ProductFruitsUserObject>(defaultUserInfo);
  const count = useRef<number>(0);
  const RETRYCOUNT = 3;
  // INCREMENT THIS TO FORCE A CACHE CLEAR FOR ALL USERS
  const CACHEVERSION = '1';
  const prod = useGetProductLevel();

  const [errorDuringLogin, setErrorDuringLogin] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  // get expected URL from runtime config if we are local host
  const configURL = publicRuntimeConfig.Domain as string;
  let loggedInURL = '';
  let tokenURL = '';

  // hey the origin from the window object
  const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

  if (origin.toString().toLowerCase().includes('localhost')) {
    loggedInURL = configURL;
  } else {
    if (origin.includes('//')) {
      loggedInURL = origin.split('//')[1].split('.')[0].split(':')[0];
    }
  }

  if (origin.includes('.myportal.')) {
    loggedInURL = loggedInURL + '.myportal';
  }

  if (typeof window !== 'undefined') {
    const oidcStorage = sessionStorage.getItem(
      'oidc.user:' + publicRuntimeConfig.Authority + ':' + publicRuntimeConfig.ClientId
    );

    if (oidcStorage) {
      tokenURL = JSON.parse(oidcStorage).profile.subdomain;

      if (JSON.parse(oidcStorage).profile.product === 'myportal') {
        tokenURL = tokenURL + '.' + JSON.parse(oidcStorage).profile.product;
      }
    }
  }

  if (tokenURL === 'www') {
    tokenURL = '';
  }

  if (tokenURL !== '' && loggedInURL.toLowerCase() !== tokenURL.toLowerCase()) {
    handleLogout();
  }

  const InitializeRecentHistory = () => {
    let userId = '';
    if (oidcStorage) {
      userId = JSON.parse(oidcStorage).profile.tenantUserId;
    }

    const existingHistory = window.localStorage.getItem(`recent-history-${userId}`);
    if (!existingHistory) {
      window.localStorage.setItem(`recent-history-${userId}`, JSON.stringify([]));
    } else {
      // Delete history entries that are over a day old
      const currentHistory: RecentHistory[] = JSON.parse(existingHistory);
      const currentDate = new Date();
      const yesterdayDate = new Date();
      yesterdayDate.setDate(currentDate.getDate() - 1);

      for (let historyIndex = 0; historyIndex < currentHistory.length; historyIndex++) {
        if (new Date(currentHistory[historyIndex].expiry) < yesterdayDate) {
          currentHistory.splice(currentHistory.indexOf(currentHistory[historyIndex]), 1);
        }
      }

      window.localStorage.setItem(`recent-history-${userId}`, JSON.stringify(currentHistory));
    }
  };

  const handleDumpCache = () => {
    const cacheVersion = window.localStorage.getItem('cacheVersion');
    if (cacheVersion === null || cacheVersion !== CACHEVERSION) {
      console.log('Clearing cache');
      window.localStorage.clear();
      window.localStorage.setItem('cacheVersion', CACHEVERSION);
    }
  };

  useEffect(() => {
    const oidc = sessionStorage.getItem(
      'oidc.user:' + publicRuntimeConfig.Authority + ':' + publicRuntimeConfig.ClientId
    );

    setOidcStorage(oidc);
  }, [auth]);

  useEffect(() => {
    if (oidcStorage) {
      const user = User.fromStorageString(oidcStorage);
      dispatch(setAuthData(user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, oidcStorage]);

  /**
   * Account configuration
   */
  useEffect(() => {
    const user = auth.user;

    if (user !== null && user !== undefined) {
      handleDumpCache();

      // Initialize recent history collection
      InitializeRecentHistory();
      window.sessionStorage.removeItem(`impersonate-${auth.user?.profile.tenantUserId}`);

      getUserByIdAsync(user.profile?.tenantUserId as string).then(res => {
        const userInfo: ProductFruitsUserObject = {
          username: user.profile?.tenantUserId as string,
          email: res.data.userProfile?.email ? res.data.userProfile?.email : '',
          firstname: res.data.userProfile?.forname ? res.data.userProfile?.forname : '',
          lastname: res.data.userProfile?.surname ? res.data.userProfile?.surname : '',
          role: res.data.userProfile?.jobTitle ? res.data.userProfile?.jobTitle : '',
          signUpAt: res.data.inviteAccepted ? res.data.inviteAccepted : '',
          props: { Depot: user?.profile.company as string, IsAdmin: auth.user?.profile.defaultRoleIsAdmin as string }
        };

        settings.currentRole = res.data.userProfile?.defaultRoleId as string;
        saveSettings({ ...settings, isImpersonating: false });

        setPfUserInfo(userInfo);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user, oidcStorage]);

  /**
   * Handle a token which has expired
   */
  useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      auth.signinSilent();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.events, auth.signinSilent]);

  /**
   * Handle unAuthenticated
   */
  useEffect(() => {
    if (!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !errorDuringLogin) {
      if (count.current < RETRYCOUNT) {
        count.current = count.current + 1;

        auth.signinRedirect().catch(err => {
          setErrorDuringLogin(true);
          console.log(err);
        });
      }
    }

    if (errorDuringLogin) {
      router.push('/503');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  // try to handle the lack of a back end!

  if (auth.isLoading && !hideSpinner) {
    return fallback;
  }

  /// need a better error page
  if (auth.error) {
    count.current = 0;

    return <div>Oops... {auth.error.message}</div>;
  }

  let workspaceCode = '';
  if (prod === Products.Flow && (origin.includes('vigocloud.com') || origin.includes('vigocloud-st.com'))) {
    workspaceCode = 'EnzpCcJ7OngncnlW';
  }
  if (prod === Products.MyPortal && (origin.includes('vigocloud.com') || origin.includes('vigocloud-st.com'))) {
    workspaceCode = '7RLv6CD8AvtOqTe9';
  }

  if (prod === Products.Flow && !(origin.includes('vigocloud.com') || origin.includes('vigocloud-st.com'))) {
    workspaceCode = 'iifPDXKafN0JeiL9';
  }
  if (prod === Products.MyPortal && !(origin.includes('vigocloud.com') || origin.includes('vigocloud-st.com'))) {
    workspaceCode = 'M3sgn4ZLI4aQTd3J';
  }

  if ((auth.isAuthenticated && !auth.activeNavigator, !auth.isLoading)) {
    count.current = 0;

    return (
      <>
        {pfUserInfo.username != '' && workspaceCode !== '' && (
          <ProductFruits workspaceCode={workspaceCode} language="en" user={pfUserInfo} />
        )}
        <div>{children}</div>
      </>
    );
  }

  return null;
};

export default AuthGuard;
