import { GetAxiosInstance } from '../baseService';
import { ServiceLocation } from '../serviceLocationsAxios';
import {
  GetAttachmentQueryParams,
  AttachmentDto,
  PodDto,
  AttachmentTypeDto,
  GetTrackingStatusImageBysalesOrderIdQueryParams,
  TrackingAttachmentDto,
  GetAttachmentByidFileQueryParams,
  GetTrackingStatusImageByidFileQueryParams,
  GetTrackingPodQueryParams,
  GetAttachmentTypeQueryParams,
  GetDtoQueryParams,
  DtoDto,
  AttachmentModel,
  GetEntityTypeAllowedAttachmentTypeQueryParams
} from '../../models/attachment';
import { AxiosRequestConfig } from 'axios';

//get EntityTypeAllowedAttachmentType For entity Type
export const getEntityTypeAllowedAttachmentTypeForDto = async (
  entityType: string,
  params?: GetEntityTypeAllowedAttachmentTypeQueryParams
) => {
  return GetAxiosInstance(undefined, undefined, undefined, undefined, undefined, undefined, true).get<any[]>(
    `${ServiceLocation.attachment}/EntityTypeAllowedAttachmentType?filter=entityTypeId%20eq%20guid'${entityType}'&ignoreOwner=true&includeMetadata=false`,
    { params }
  );
};

//get attachment Types
export const getAttachmentTypes = async (params?: GetAttachmentTypeQueryParams) => {
  return GetAxiosInstance(undefined, undefined, undefined, undefined, undefined, undefined, true).get<
    AttachmentTypeDto[]
  >(ServiceLocation.attachment + '/AttachmentType', { params });
};

//get Attachments For Entity
export const getAttachmentsForEntity = async (entity: string, params?: any) => {
  return GetAxiosInstance().get<AttachmentDto[]>(
    `${ServiceLocation.attachment}/Attachment?filter=entityId eq guid'${entity}'`,
    {
      params
    }
  );
};

/**
 * Get attachements
 *
 * @param params GetAttachmentQueryParams
 * @returns AttachmentDto[]
 */
export const getAttachments = (params?: GetAttachmentQueryParams) => {
  return GetAxiosInstance().get<AttachmentDto[]>(`${ServiceLocation.attachment}/Attachment`, { params });
};

/**
 * Get attachments for pod
 *
 * @param params
 * @returns
 */
export const getPod = (params?: GetTrackingPodQueryParams) => {
  return GetAxiosInstance().get<PodDto[]>(ServiceLocation.attachment + '/Tracking/pod', {
    params
  });
};

/**
 * Get Tracking Attachments by Sales Order Id
 * @param id Id of the Sales Order
 * @param params GetTrackingStatusImageBysalesOrderIdQueryParams
 * @returns TrackingAttachmentDto[]
 */
export const getTrackingAttachments = (id: string, params?: GetTrackingStatusImageBysalesOrderIdQueryParams) => {
  return GetAxiosInstance().get<TrackingAttachmentDto[]>(ServiceLocation.attachment + '/Tracking/statusImage/' + id, {
    params
  });
};

export const getTrackingAttachmentFile = (id: string, params?: GetTrackingStatusImageByidFileQueryParams) => {
  return GetAxiosInstance(undefined, undefined, undefined, undefined, 'blob').get<any>(
    ServiceLocation.attachment + '/Tracking/statusImage/' + id + '/file',
    { params }
  );
};

/**
 * Get file as blob
 */
export const getFile = (id: string, params?: GetAttachmentByidFileQueryParams) => {
  return GetAxiosInstance(undefined, undefined, undefined, undefined, 'blob').get<any>(
    ServiceLocation.attachment + '/Attachment/' + id + '/file',
    { params }
  );
};

/**
 * Get attachment file
 */
export const getAttachmentFile = (id: string, params?: GetAttachmentByidFileQueryParams) => {
  return GetAxiosInstance().get<any>(`${ServiceLocation.attachment}/Attachment/${id}/file`, { params });
};

/**
 * Get attachment file base64 string
 */
export const getAttachmentBase64 = (id: string) => {
  return GetAxiosInstance().get<string>(`${ServiceLocation.attachment}/Attachment/${id}/file/base64`);
};

/**
 * Delete attachment by id
 *
 * @param params
 * @returns
 */
export const deleteAttachmentById = (params: {
  id: string;
  data: AxiosRequestConfig<{
    remarks: string;
    version: string;
  }>;
}) => {
  return GetAxiosInstance().delete(`${ServiceLocation.attachment}/Attachment/${params.id}`, params.data);
};

/**
 * Get Dtos (Attachment Service)
 * @param params GetDtoQueryParams
 * @returns DtoDto[]
 */
export const getAttachmentDtos = (params?: GetDtoQueryParams) => {
  return GetAxiosInstance().get<DtoDto[]>(ServiceLocation.attachment + '/dto', { params });
};

/**
 * Update attachment
 *
 * @param id
 * @param model
 * @returns
 */
export const toggleAttachmentIsHidden = (id: string, model: AttachmentModel) => {
  return GetAxiosInstance().put(`${ServiceLocation.attachment}/Attachment/${id}/toggleHidden`, model);
};
