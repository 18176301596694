import { PayloadAction } from '@reduxjs/toolkit';
import { UIFlowState } from '..';
import { ButtonDefinition } from 'src/@vigo/vigo-multiButton';

/**
 * Item View Toolbar Reducers
 */

const setAddModeSaveButtonGroup = (state: UIFlowState, action: PayloadAction<ButtonDefinition[]>) => {
  state.itemViewToolbarState.saveButtonGroup.addMode = action.payload;
};

const resetAddModeSaveButtonGroup = (state: UIFlowState) => {
  state.itemViewToolbarState.saveButtonGroup.addMode = [];
};

const setEditModeSaveButtonGroup = (state: UIFlowState, action: PayloadAction<ButtonDefinition[]>) => {
  state.itemViewToolbarState.saveButtonGroup.editMode = action.payload;
};

const resetEditModeSaveButtonGroup = (state: UIFlowState) => {
  state.itemViewToolbarState.saveButtonGroup.editMode = [];
};

const resetAllSaveButtonGroups = (state: UIFlowState) => {
  state.itemViewToolbarState.saveButtonGroup.addMode = [];
  state.itemViewToolbarState.saveButtonGroup.editMode = [];
};

const setShouldPersistButtonSelection = (state: UIFlowState, action: PayloadAction<boolean>) => {
  state.itemViewToolbarState.shouldPersistButtonSelection = action.payload;
};

export const itemViewToolbarReducer = {
  setAddModeSaveButtonGroup,
  resetAddModeSaveButtonGroup,
  setEditModeSaveButtonGroup,
  resetEditModeSaveButtonGroup,
  resetAllSaveButtonGroups,
  setShouldPersistButtonSelection
};
