import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

type AddressLineType = 'collectionAddress' | 'deliveryAddress';

interface AddressLine {
  postcodeWarning?: string;
  networkNotes: string;
}

export interface ConsignmentSliceState {
  collectionAddress: AddressLine;
  deliveryAddress: AddressLine;
}

const initialState: ConsignmentSliceState = {
  collectionAddress: {
    networkNotes: ''
  },
  deliveryAddress: {
    postcodeWarning: '',
    networkNotes: ''
  }
};

export const consignmentSlice = createSlice({
  name: 'consignment',
  initialState: initialState,
  reducers: {
    setDeliveryPostcodeWarning: (state, action: PayloadAction<string>) => {
      state.deliveryAddress.postcodeWarning = action.payload;
    },
    setNetworkNotes: (state, action: PayloadAction<{ line: AddressLineType; notes: string }>) => {
      set(state, action.payload.line + '.networkNotes', action.payload.notes);
    },
    resetAddressLine: (state, action: PayloadAction<AddressLineType>) => {
      state[action.payload] = initialState[action.payload];
    }
  }
});

export const { setDeliveryPostcodeWarning, setNetworkNotes, resetAddressLine } = consignmentSlice.actions;

export default consignmentSlice.reducer;
